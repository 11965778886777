import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import dayjs from '@/lib/dayjs'

export const useRangeTime = () => {
  const getTodayRange = () => {
    const start = startOfDay(new Date()).toISOString()
    const end = endOfDay(new Date()).toISOString()
    return [start, end]
  }

  const getWeekRange = () => {
    const start = startOfWeek(new Date()).toISOString()
    const end = endOfWeek(new Date()).toISOString()
    return [start, end]
  }

  const getMonthRange = () => {
    const start = startOfMonth(new Date()).toISOString()
    const end = endOfMonth(new Date()).toISOString()
    return [start, end]
  }

  const getYearRange = () => {
    const start = dayjs().startOf('year')
    const end = dayjs().endOf('year').add(1, 'second')
    return [start.toDate(), end.toDate()]
  }

  return {
    getTodayRange,
    getWeekRange,
    getMonthRange,
    getYearRange,
  }
}
