<template>
  <div class="range-time-select">
    <el-button-group class="group-time-filter-button">
      <el-button
        class="date-range-btn"
        :class="{ active: data.timeRangeType === 'today' }"
        @click="changeType('today')"
      >
        本日
      </el-button>
      <el-button
        class="date-range-btn"
        :class="{ active: data.timeRangeType === 'week' }"
        @click="changeType('week')"
      >
        本週
      </el-button>
      <el-button
        class="date-range-btn"
        :class="{ active: data.timeRangeType === 'month' }"
        @click="changeType('month')"
      >
        本月
      </el-button>
      <el-button
        class="date-range-btn"
        :class="{ active: data.timeRangeType === 'custom' }"
      >
        自定義
      </el-button>
    </el-button-group>

    <el-date-picker
      v-model="data.date"
      class="datetime__picker"
      type="datetimerange"
      range-separator="至"
      start-placeholder="開始時間"
      end-placeholder="結束時間"
      format="yyyy-MM-dd HH:mm"
      :default-time="['00:00:00', '23:59:59']"
      @clear="changeType('today')"
      @change="changeType('custom', $event)"
    />
  </div>
</template>
<script setup>
import { reactive, watch } from 'vue'
import { useRangeTime } from '@/use/rangeTime'

const emit = defineEmits(['today', 'week', 'month', 'custom'])
const {
  getTodayRange,
  getWeekRange,
  getMonthRange,
} = useRangeTime()

const data = reactive({
  timeRangeType: 'today',
  date: null,
})

const changeType = (type, e) => {
  data.timeRangeType = type
  let dateRange = e
  if (type === 'today') {
    data.date = null
    dateRange = getTodayRange()
  } else if (type === 'week') {
    data.date = null
    dateRange = getWeekRange()
  } else if (type === 'month') {
    data.date = null
    dateRange = getMonthRange()
  } else if (type === 'custom') {
    if (!e) {
      type = 'today'
      data.timeRangeType = 'today'
      dateRange = getTodayRange()
    }
  }
  emit(type, { type, date: dateRange })
}

</script>

<style lang="postcss" scoped>
.range-time-select {
  @apply flex items-center justify-between;
}
.group-time-filter-button {
  @apply max-w-[566px];
}
.active {
  @apply border border-primary-100 bg-primary-100 text-white;
}

::v-deep .el-button {
  @apply shadow-none;
}

::v-deep .el-button:hover {
  @apply bg-primary-100 border-primary-100 text-white;
}

.date-range-btn {
  @apply px-[53.75px];
}
</style>
